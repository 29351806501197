import React, { useState, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import NavIndex from 'library/data/nav-index'
import LinkIndex from 'library/data/link-index'

export const AppContext = React.createContext()

const initialClasses = [
  "hide-lore",
  "hide-pop",
  "show-options",
  "expand-cross",
  "hide-notice",
  "hide-actions",
  "dark-mode"
]

export const AppContextProvider = ({ children }) => {

  // Set states for context pieces
  const [prefClasses, setPrefClasses] = useState([])
  const [classView, setClassView] = useState('default-view')

  // Get routing object for setting classes
  const router = useRouter()

  // Apply any found pref classes
  useEffect(() => {
    const bodyClasses = document.body.classList
    const prefs = localStorage.getItem(`${process.env.NEXT_PUBLIC_TAG}_prefs`)
    if (prefs) {
      const prefsObj = JSON.parse(prefs)
      if (prefsObj.prefs && Array.isArray(prefsObj.prefs)) {
        setPrefClasses(prevState => {
          return [ ...prevState, ...prefsObj.prefs ]
        })
      }
    }
  }, [])

  // Clear/hide search on route change
  useEffect(() => {
    if (router.query.minimal) setClassView('minimal-view')
    if (router.query.clean) setClassView('clean-view')
  }, [router.query])

  // Update body and local with prefs change
  useEffect(() => {
    const bodyClasses = document.body.classList
    const uniqueArray = [...new Set(prefClasses)]
    for (const prefClass of initialClasses) bodyClasses.remove(prefClass) // Clear all
    for (const prefClass of uniqueArray) bodyClasses.add(prefClass) // Add selected
    localStorage.setItem(`${process.env.NEXT_PUBLIC_TAG}_prefs`, JSON.stringify({ prefs: uniqueArray }))
  }, [prefClasses])

  return (
    <AppContext.Provider value={{
      prefClasses: prefClasses,
      setPrefClasses: setPrefClasses,
      classView: classView,
      navIndex: NavIndex,
      linkIndex: LinkIndex
    }}>
      {children}
    </AppContext.Provider>
  )
}