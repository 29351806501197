import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { AppContextProvider } from 'library/context'

import { trackPageview } from 'library/helpers/ga'

import NextNprogress from 'nextjs-progressbar'

import 'normalize.css'
import 'styles/globals.scss'

const App = ({ Component, pageProps }) => {

  // Use router to track page sends
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = url => {
      trackPageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // Fix for unstyled CSS flash
  useEffect(() => {
    const el = document.getElementsByTagName('html');
    if (el) el[0].classList.remove('fouc');
  }, [])

  // Build edition path
  const edition = process.env.NEXT_PUBLIC_EDITION ? `${process.env.NEXT_PUBLIC_EDITION}/` : ''

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="apple-touch-icon" sizes="180x180" href={`/icons/${edition}apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/icons/${edition}favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/icons/${edition}favicon-16x16.png`} />
        <link rel="manifest" href={`/icons/${edition}site.webmanifest`} />
        <link rel="mask-icon" href={`/icons/${edition}safari-pinned-tab.svg`} color="#212121" />
        <link rel="shortcut icon" href={`/icons/${edition}favicon.ico`} />
        <meta name="msapplication-TileColor" content="#212121" />
        <meta name="msapplication-config" content={`/icons/${edition}/browserconfig.xml`} />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <AppContextProvider>
        <NextNprogress />
        <Component {...pageProps} />
      </AppContextProvider>
    </>
  )
}

export default App