// Tracking page views
export const trackPageview = (url) => {
  if (typeof gtag !== 'undefined') {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    })
  }
}

// Track user event
export const trackEvent = ({ action, params }) => {
  if (typeof gtag !== 'undefined') window.gtag('event', action, params)
}